/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useState, useRef } from "react";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
// Images
import bgImage from "assets/images/background.png";

import client from "ApiClient";
import md5 from "md5";
import MUIDataTable from "mui-datatables";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const closeErrorSB = () => setErrorSB(false);

  const handleLogin = () => {
    const options = {
      method: "GET",
      url: `users/adminlogin`,
      params: {
        email: emailRef.current.lastChild.firstChild.value,
        password: md5(passwordRef.current.lastChild.firstChild.value),
      },
    };
    client
      .request(options)
      .then((response) => {
        if (JSON.stringify(response) === "[{}]") setErrorSB(true);
        else {
          localStorage.setItem("AuthorizationToken", JSON.stringify(response.user_token));
          localStorage.setItem("userName", response.user_name);
          navigate("users"); // window.location.href = "/"; // navigate("/blogArticles");
          window.location.reload();
        }
      })
      .catch(() => {
        setErrorSB(true);
      });
  };

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Login failed"
      content="Your credentails are wrong"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth ref={emailRef} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth ref={passwordRef} />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" onClick={handleLogin} color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
          <div style={{ height: "100%", width: "100%" }} hidden>
            <MUIDataTable />
          </div>
        </MDBox>
        {renderErrorSB}
      </Card>
    </BasicLayout>
  );
}

export default Basic;
