import { useEffect, useRef, useState } from "react";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { MenuItem, Select } from "@material-ui/core";

/* eslint-disable */
function Edit(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const closeAlert = () => setOpenAlert(false);

  const [errorSB, setErrorSB] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [quota, setQuota] = useState(0);
  const [userEnabled, setUserEnabled] = useState(false);

  const { openModal, closeModal } = props;
  const [user, setUser] = useState(props.user);
  const [settings, setSettings] = useState([]);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [errorContent, setErrorContent] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [openErrorSB, setOpenErrorSB] = useState(false);
  const closeErrorSB = () => setOpenErrorSB(false);
  const [openTestSB, setOpenTestSB] = useState(false);
  const closeTestSB = () => setOpenTestSB(false);

  const [setId, setSetId] = useState("");
  const [unsubscribeUrl, setUnsubscribeUrl] = useState("");
  const [unsubscribeHTML, setUnsubscribeHTML] = useState("");
  const [fromDomain, setFromDomain] = useState("");
  const [totalDailySends, setTotalDailySends] = useState(100);
  const [noEngagementLimitSends, setNoEngagementLimitSends] = useState("");
  const [initialHourOfDay, setInitialHourOfDay] = useState("");

  const [senders, setSenders] = useState(props.senders);
  const [selectedSender, setSelectedSenders] = useState(1);

  const [companyName, setCompanyName] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [contactUsURL, setContactUsURL] = useState("");

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const clearVariables = () => {
    props.closeModal();
  };

  useEffect(() => {
    setIsLoading(true);
    if (props.user != undefined);
    {
      setName(props.user.user_name);
      setEmail(props.user.user_email);
      setUserEnabled(props.user.user_enabled);
      setQuota(props.user.user_quota);
      setUser(props.user);
      setSenders(props.senders);
      getSettings();
    }
  }, [props]);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Validation failed"
      content="Please review al fields"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const renderAlert = (
    <MDSnackbar
      color="success"
      icon="success"
      title="Settings saved"
      content=""
      dateTime=""
      open={openAlert}
      onClose={closeAlert}
      close={closeAlert}
      bgWhite
    />
  );

  const options = {
    method: "POST",
    url: `emailCopy/Preview`,
    headers: {
      "content-type": "application/json",
    },
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "PUT",
        url: `users`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        user_apiKey: user.user_apiKey,
        user_email: email,
        user_enabled: userEnabled,
        user_id: user.user_id,
        user_name: name,
        user_password: user.user_password,
        user_quota: quota,
        user_token: user.user_token,
        user_tokenExpires: user.user_tokenExpires,
        user_type: user.user_type,
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  const getSettings = async () => {
    if (props.user.user_id != undefined) {
      setIsLoading(true);
      options.method = "GET";
      options.url = `settings?user_id=${props.user.user_id}`;
      await client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          setSettings(response);
          setSetId(response.set_id);
          setNoEngagementLimitSends(response.set_noEngagementLimit);
          setInitialHourOfDay(response.set_preferredHour);
          setUnsubscribeHTML(response.set_unsubscribeHTML);
          setUnsubscribeUrl(response.set_unsubscribePageURL);
          setFromDomain(response.set_fromDomain);
          setTotalDailySends(response.set_totalDailySends);
          setCompanyName(response.set_companyName);
          setCompanyCountry(response.set_companyCountry);
          setCompanyState(response.set_companyState);
          setCompanyCity(response.set_companyCity);
          setCompanyAddress(response.set_companyAddress);
          setContactUsURL(response.set_contactUsURL);
          setSelectedSenders(response.set_ema_id);
        })
        .catch((error) => {
          setIsLoading(false);
          // eslint-disable-next-line
          console.log(error.response.status);
        });
    }
  };

  const unsubscribeUrlChange = (event) => {
    setUnsubscribeUrl(event.target.value);
  };

  const fromDomainChange = (event) => {
    setFromDomain(event.target.value);
  };

  const onChangeUnsubscribeHTML = (event) => {
    setUnsubscribeHTML(event.target.value);
  };

  const totalDailySendsChange = (event) => {
    setTotalDailySends(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const companyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const companyCountryChange = (event) => {
    setCompanyCountry(event.target.value);
  };

  const companyStateChange = (event) => {
    setCompanyState(event.target.value);
  };

  const companyCityChange = (event) => {
    setCompanyCity(event.target.value);
  };

  const companyAddressChange = (event) => {
    setCompanyAddress(event.target.value);
  };

  const contactUsURLChange = (event) => {
    setContactUsURL(event.target.value);
  };

  const handleSaveSettings = async () => {
    setIsSaving(true);
    options.method = "PUT";
    options.url = `settings`;
    console.log(settings);
    options.data = JSON.stringify({
      set_id: settings.set_id,
      set_user_id: settings.set_user_id,
      set_gptApiKey: settings.set_gptApiKey,
      set_unsubscribePageURL: unsubscribeUrl,
      set_fromDomain: fromDomain,
      set_totalDailySends: totalDailySends,
      set_totalDailySendsWelcome: totalDailySends,
      set_noEngagementLimit: noEngagementLimitSends,
      set_preferredHour: initialHourOfDay,
      set_unsubscribeHTML: unsubscribeHTML,
      set_running: settings.set_running,
      set_companyName: companyName,
      set_companyCountry: companyCountry,
      set_companyState: companyState,
      set_companyCity: companyCity,
      set_companyAddress: companyAddress,
      set_contactUsURL: contactUsURL,
      set_mauticURL: settings.set_mauticURL,
      set_mauticPassword: settings.set_mauticPassword,
      set_mauticUsername: settings.set_mauticUsername,
      set_activeCampaignApiKey: settings.set_activeCampaignApiKey,
      set_activeCampaignURL: settings.set_activeCampaignURL,
      set_timezone: settings.set_timezone,
      set_articlesSuggestionHTML: "",
      set_ema_id: selectedSender,
    });

    console.log(options.data);
    await client
      .request(options)
      .then(() => {
        setIsSaving(false);
        setOpenAlert(true);
      })
      .catch((error) => {
        setIsSaving(false);
        // eslint-disable-next-line
      });
  };

  return (
    <Dialog
      open={openModal}
      onClose={clearVariables}
      fullScreen
      sx={{
        //You can copy the code below in your theme
        background: "#F0F2F5",
        "& .MuiPaper-root": {
          background: "#F0F2F5",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Edit a user {name}
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox component="form" role="form">
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <MDBox
                mx={2}
                mt={1}
                mb={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="secondary"
              >
                <FormControlLabel
                  value="end"
                  labelPlacement="end"
                  control={
                    <Switch checked={userEnabled} onChange={() => setUserEnabled(!userEnabled)} />
                  }
                  label={
                    <MDTypography variant="subtitle1" color="white">
                      User {userEnabled ? "enabled" : "disabled"}
                    </MDTypography>
                  }
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Name"
                  fullWidth
                  value={name}
                  onChange={handleChangeName}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("Name", name, "required")}
                </MDBox>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={handleChangeEmail}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("email", email, "required|email")}
                </MDBox>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="number"
                  label="Send quota"
                  value={quota}
                  onChange={(e) => setQuota(e.target.value)}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("Name", name, "required")}
                </MDBox>
              </MDBox>
              <MDBox m={2}>
                <MDButton
                  onClick={handleSave}
                  disabled={isSaving}
                  color="info"
                  variant="gradient"
                  autoFocus
                >
                  Save
                </MDButton>
                {isSaving && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                  />
                )}
              </MDBox>
              <Grid container spacing={5} p={2}>
                <Grid item xs={6}>
                  <Card style={{ height: "700px" }}>
                    <MDBox
                      mx={2}
                      mt={1}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="secondary"
                      borderRadius="lg"
                      color="white"
                      coloredShadow="secondary"
                    >
                      <Typography>E-mail</Typography>
                    </MDBox>
                    <MDBox m={2}>
                      <MDBox mb={2} display="flex">
                        <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                          Default sender
                        </MDTypography>
                        <Select
                          disabled={isLoading}
                          onChange={(e) => setSelectedSenders(e.target.value)}
                          value={selectedSender}
                          label="selec a sender to change"
                        >
                          {senders.map((item) => (
                            <MenuItem key={item.ema_id} value={item.ema_id}>
                              {item.ema_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Unsubscribe URL"
                        fullWidth
                        value={unsubscribeUrl}
                        onChange={unsubscribeUrlChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "unsubscribeUrl",
                          unsubscribeUrl,
                          "required|url"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <TextField
                        label="Unsubscribe HTML paragraph"
                        fullWidth
                        multiline
                        value={unsubscribeHTML}
                        onChange={onChangeUnsubscribeHTML}
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "unsubscribeHTML",
                          unsubscribeHTML,
                          "required"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="From domain"
                        fullWidth
                        value={fromDomain}
                        onChange={fromDomainChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("fromDomain", fromDomain, "required")}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="number"
                        label="Total daily sends"
                        fullWidth
                        value={totalDailySends}
                        onChange={totalDailySendsChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "totalDailySends",
                          totalDailySends,
                          "required"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="number"
                        label="No Engagement limit sends"
                        fullWidth
                        value={noEngagementLimitSends}
                        onChange={(event) => setNoEngagementLimitSendsChange(event.target.value)}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "noEngagementLimitSends",
                          noEngagementLimitSends,
                          "required|numeric"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="number"
                        label="Initial send hour of day"
                        min={1}
                        max={24}
                        value={initialHourOfDay}
                        onChange={(event) => setInitialHourOfDay(event.target.value)}
                        required
                      />
                    </MDBox>
                    <MDBox m={2}>
                      <MDButton
                        onClick={handleSaveSettings}
                        disabled={isSaving}
                        color="info"
                        variant="gradient"
                        autoFocus
                      >
                        Save
                      </MDButton>
                      {isSaving && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                      )}
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card style={{ height: "700px" }}>
                    <MDBox
                      mx={2}
                      mt={1}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="secondary"
                      borderRadius="lg"
                      color="white"
                      coloredShadow="secondary"
                    >
                      <Typography>Company</Typography>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company Name"
                        fullWidth
                        value={companyName}
                        onChange={companyNameChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("companyName", companyName, "required")}
                      </MDBox>
                    </MDBox>

                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company State"
                        fullWidth
                        value={companyState}
                        onChange={companyStateChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("companyState", companyState, "required")}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company Country"
                        fullWidth
                        value={companyCountry}
                        onChange={companyCountryChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "companyCountry",
                          companyCountry,
                          "required"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company City"
                        fullWidth
                        value={companyCity}
                        onChange={companyCityChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("companyCity", companyCity, "required")}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company Address"
                        fullWidth
                        value={companyAddress}
                        onChange={companyAddressChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "companyAddress",
                          companyAddress,
                          "required"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Contact Us URL"
                        fullWidth
                        value={contactUsURL}
                        onChange={contactUsURLChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message(
                          "contactUsURL",
                          contactUsURL,
                          "required|url"
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDButton
                        onClick={handleSaveSettings}
                        disabled={isSaving}
                        color="info"
                        variant="gradient"
                        autoFocus
                      >
                        Save
                      </MDButton>
                      {isSaving && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: "relative", top: 4 }}
                        />
                      )}
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          autoFocus
          onClick={clearVariables}
          disabled={isLoading}
          variant="gradient"
          color="secondary"
        >
          Close
        </MDButton>
      </DialogActions>

      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content="Your test email has been sending successfully"
        dateTime={""}
        open={openTestSB}
        onClose={closeTestSB}
        close={closeTestSB}
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={errorTitle}
        content={errorContent}
        dateTime={""}
        open={openErrorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
    </Dialog>
  );
}
export default Edit;
