import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
// import WpSetup from "components/wpSetup";
import brandWhite from "./assets/images/mailsense-logo.png";

/* eslint-disable */
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const isInitiallyLogged =
    localStorage.getItem("AuthorizationToken") !== null &&
    localStorage.getItem("AuthorizationToken") !== "undefined";

  // websocket "wss://io.mailsense.ai/hub/"
  // process.env.REACT_APP_WEBSOCKET_BASE_URL ? process.env.REACT_APP_WEBSOCKET_BASE_URL : ""

  ////////////////////////////////////////

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      let returnRoute = "";
      if (route.route) {
        if (
          route.route === "/authentication/sign-in" ||
          route.route === "/authentication/sign-up" ||
          route.route === "/"
        )
          returnRoute = (
            <Route exact path={route.route} element={route.component} key={route.key} />
          );
        else
          returnRoute = isInitiallyLogged ? (
            <Route exact path={route.route} element={route.component} key={route.key} />
          ) : (
            ""
          );
        return returnRoute;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isInitiallyLogged &&
        pathname != "/authentication/sign-in" &&
        pathname != "/authentication/sign-up" &&
        pathname != "/" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brandWhite}
              brandName="MailSense"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
      <Routes>
        {getRoutes(routes)}
        {isInitiallyLogged ? (
          <Route path="/users" element={<Navigate to="/users" />} />
        ) : (
          <Route
            path="/authentication/sign-in"
            element={<Navigate to="/authentication/sign-in" />}
          />
        )}
      </Routes>
    </ThemeProvider>
  );
}
