/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
// import Table from "@mui/material/Table";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationAlert from "components/ConfirmationAlert";
import client from "ApiClient";

import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import CustomToolbarSelect from "./customToolbarSelect";
import Edit from "./edit";

/* eslint-disable */
export default function Table(props) {
  const [acconuntsData, setAcconuntsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [senders, setSenders] = useState([]);
  const [sendersSelected, setSendersSelected] = useState([]);
  const [openLog, setOpenLog] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [previewTemplate, setPreviewTemplate] = useState("");
  const [subjectPreview, setSubjectPreview] = useState("");
  const handleOpenModal = () => setOpenModal(true);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "emailSenders",
  };

  const getData = async () => {
    options.method = "GET";
    options.url = `emailSenders`;
    await client
      .request(options)
      .then((response) => {
        if (JSON.stringify(response) !== "[{}]") setAcconuntsData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [props.groupAdded]);

  useEffect(() => {
    if (!openLog) {
      setIsLoading(true);
      setAcconuntsData([]);
      setPreviewTemplate("");
      setSubjectPreview("");
      getData();
    }
  }, [openLog]);

  const handleEdit = () => {
    handleOpenModal();
  };

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `emailSenders?id=${sendersSelected[0].ema_id}`;

    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        // setIsLoading(false);
        setOpenAlert(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [props.itemAdded]);

  const getType = (ema) => {
    switch (ema.ema_type) {
      case 0:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium">
            Amazon
          </MDTypography>
        );
      case 1:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium">
            SMTP.com
          </MDTypography>
        );
    }
  };

  const getConfiguration = (ema) => {
    switch (ema.ema_type) {
      case 0:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              Access: :{" "}
            </MDTypography>
            <MDTypography variant="body">{ema.ema_auth1}</MDTypography>
            <br></br>
            <MDTypography variant="body" fontWeight="medium">
              Secret:{" "}
            </MDTypography>
            <MDTypography variant="body">{ema.ema_auth2}</MDTypography>
            <br></br>
            <MDTypography variant="body" fontWeight="medium">
              Region:{" "}
            </MDTypography>
            <MDTypography variant="body"> {ema.ema_auth3}</MDTypography>
          </div>
        );
      case 1:
        return (
          <div>
            <MDTypography variant="body" fontWeight="medium">
              API key: :{" "}
            </MDTypography>
            <MDTypography variant="body">{ema.ema_auth1}</MDTypography>
            <br></br>
            <MDTypography variant="body" fontWeight="medium">
              Chanel:{" "}
            </MDTypography>
            <MDTypography variant="body">{ema.ema_auth2}</MDTypography>
          </div>
        );
    }
  };

  const columns = [
    {
      label: "Type",
      name: "ema_status",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => getType(acconuntsData[dataIndex]),
      },
    },
    {
      label: "Name",
      name: "ema_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Description",
      name: "ema_description",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    /*
    {
      label: "Configuration",
      name: "ema_id",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => getConfiguration(acconuntsData[dataIndex]),
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    */
    {
      label: " ",
      name: "ema_id",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setSenders(acconuntsData[dataIndex]);
                  setOpenLog(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const customToolbar = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "you don't have any senders",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "sender/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    downloadOptions: { filename: "senders.csv", separator: "," },
    searchOpen: true,
    searchAlwaysOpen: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    searchPlaceholder: "Search",
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      let selected = [];
      allRowsSelected.map((row) => {
        selected.push(acconuntsData[row.dataIndex]);
      });
      setSendersSelected(selected);
    },
    customToolbarSelect: customToolbar,
    options: {
      setTableProps: () => {
        return {
          // material ui v4 only
          size: "small",
        };
      },
    },
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this sender?"
      />
      <Edit
        openModal={openLog}
        closeModal={() => {
          getData();
          setOpenLog(false);
        }}
        senders={senders}
      />
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="warning"
      >
        <MDTypography variant="h2" color="white">
          Senders
          {isLoading && (
            <CircularProgress size={30} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDTypography>

        <MDBox variant="gradient" bgColor="secondary" borderRadius="lg" coloredShadow="secondary">
          <IconButton onClick={props.onAdd} sx={{ fontWeight: "bold" }}>
            <Tooltip id="button-add" title="Create a new broadcast">
              <AddIcon color="white" />
            </Tooltip>
          </IconButton>
        </MDBox>
      </MDBox>

      <Grid container p={2}>
        <Grid item xs={12}>
          <div style={{ height: "100%", width: "100%" }}>
            <MUIDataTable data={acconuntsData} columns={columns} options={tableOptions} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
