import { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import { CircularProgress, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { MenuItem, Select } from "@material-ui/core";

/* eslint-disable */
function Add(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [auth1, setAuth1] = useState("");
  const [auth2, setAuth2] = useState("");
  const [auth3, setAuth3] = useState("");
  const [type, setType] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { openModal, closeModal } = props;

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const handleSave = async () => {
    setIsLoading(true);
    const options = {
      method: "POST",
      url: `emailSenders`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify({
      ema_type: type,
      ema_name: name,
      ema_description: description,
      ema_apiURL: "",
      ema_auth1: auth1,
      ema_auth2: auth2,
      ema_auth3: auth3,
      ema_status: 0,
    });
    console.log(options.data);
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        props.onDone();
        props.closeModal();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  return (
    <Dialog open={openModal} onClose={closeModal} fullScreen>
      <Card spacing={2}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={2}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
            Add a sender
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={2}>
          <DialogContent>
            <MDBox component="form" role="form">
              <Card>
                <MDBox mb={2} mt={2} ml={2} mr={2}>
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <Select
                      disabled={isLoading}
                      onChange={(e) => setType(e.target.value)}
                      value={type}
                    >
                      <MenuItem key={0} value={0}>
                        Amazon SES
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        SMTP.com
                      </MenuItem>
                    </Select>
                  </MDBox>
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <MDInput
                      type="text"
                      label="Friendly name"
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </MDBox>
                  <MDBox pb={2} display="flex" justifyContent="left">
                    <MDInput
                      type="text"
                      label="Description"
                      multiline
                      fullWidth
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </MDBox>
                  {type === 0 && (
                    <>
                      <MDBox pb={2} display="flex" justifyContent="left">
                        <TextField
                          type="text"
                          label="Access"
                          fullWidth
                          value={auth1}
                          onChange={(e) => setAuth1(e.target.value)}
                        />
                      </MDBox>
                      <MDBox pb={2} display="flex" justifyContent="left">
                        <TextField
                          type="text"
                          label="Secret"
                          fullWidth
                          value={auth2}
                          onChange={(e) => setAuth2(e.target.value)}
                        />
                      </MDBox>
                      <MDBox pb={2} display="flex" justifyContent="left">
                        <TextField
                          type="text"
                          label="Region"
                          fullWidth
                          value={auth3}
                          onChange={(e) => setAuth3(e.target.value)}
                        />
                      </MDBox>
                    </>
                  )}
                  {type === 1 && (
                    <>
                      <MDBox pb={2} display="flex" justifyContent="left">
                        <TextField
                          type="text"
                          label="API Key"
                          fullWidth
                          value={auth1}
                          onChange={(e) => setAuth1(e.target.value)}
                        />
                      </MDBox>
                      <MDBox pb={2} display="flex" justifyContent="left">
                        <TextField
                          type="text"
                          label="Channel"
                          fullWidth
                          value={auth2}
                          onChange={(e) => setAuth2(e.target.value)}
                        />
                      </MDBox>
                    </>
                  )}
                </MDBox>
              </Card>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton
              autoFocus
              onClick={closeModal}
              disabled={isLoading}
              variant="gradient"
              color="secondary"
            >
              Cancel
            </MDButton>
            <MDButton onClick={handleSave} autoFocus variant="gradient" color="info">
              Save
              {isLoading && (
                <MDBox display="flex">
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                  />
                </MDBox>
              )}
            </MDButton>
          </DialogActions>
        </MDBox>
      </Card>
    </Dialog>
  );
}

export default Add;
