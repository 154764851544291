import { Card, CircularProgress, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import { useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

/* eslint-disable */
export default function Billing(props) {
  const [addGroup, setAddGroup] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleAddGroup = () => {
    const refresh = addGroup + 1;
    setAddGroup(refresh);
  };

  const handleSaveWordpress = () => {};

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3} pt={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card style={{ height: "700px" }}>
              <MDBox
                mx={2}
                mt={1}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                color="white"
                coloredShadow="secondary"
              >
                <Typography>Stripe integration</Typography>
              </MDBox>
              <MDBox m={2}>
                <MDInput
                  type="text"
                  label="API key"
                  fullWidth
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                  required
                />
              </MDBox>
              <MDBox m={2}>
                <MDButton
                  onClick={handleSaveWordpress}
                  disabled={isSaving}
                  color="info"
                  variant="gradient"
                  autoFocus
                >
                  Save
                </MDButton>
                {isSaving && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
