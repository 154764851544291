import { useEffect, useState } from "react";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import client from "ApiClient";
import { CircularProgress, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import Moment from "moment";
import MUIDataTable from "mui-datatables";
import { MenuItem, Select } from "@material-ui/core";
import MDSnackbar from "components/MDSnackbar";

/* eslint-disable */
function Details(props) {
  const { openModal, closeModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [acconuntsData, setAcconuntsData] = useState([]);
  const [senders, setSenders] = useState([]);
  const [selectedSender, setSelectedSenders] = useState(0);
  const [emailCopy, setEmailCopy] = useState();

  const [previewTemplate, setPreviewTemplate] = useState("");
  const [subjectPreview, setSubjectPreview] = useState("");

  const [previewPrevious, setPreviewPrevious] = useState("");
  const [subjectPrevious, setSubjectPrevious] = useState("");

  const [openError, setOpenError] = useState(false);
  const closeError = () => setOpenError(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const closeSuccess = () => setOpenSuccess(false);

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const clearVariables = () => {
    setIsLoading(false);
    props.closeModal();
  };

  useEffect(() => {
    if (props.broadcast != undefined) {
      setIsLoading(true);
      getSenders();
      getBroadcasts();
      getEmailCopy(props.broadcast.bro_emc_id);
    }
  }, [props.broadcast]);

  useEffect(() => {
    if (emailCopy != undefined) {
      if (props.broadcast.bro_emc_id == emailCopy.emc_id) preview();
      else hanldePreviewPrevious();
    }
  }, [emailCopy]);

  useEffect(() => {
    if (props.broadcast != undefined && selectedSender != props.broadcast.bro_ema_id) {
      changeSender();
    }
  }, [selectedSender]);

  useEffect(() => {
    setSelectedSenders(props.broadcast.bro_ema_id);
  }, [senders]);

  const options = {
    method: "GET",
    url: "broadcasts",
  };

  const getSenders = async () => {
    options.method = "GET";
    options.url = `emailSenders`;
    await client
      .request(options)
      .then((response) => {
        setSenders(response);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getBroadcasts = async () => {
    options.method = "GET";
    options.url = `broadcasts/GetByUser?user_id=${props.broadcast.bro_user_id}`;
    await client
      .request(options)
      .then((response) => {
        if (JSON.stringify(response) !== "[{}]") setAcconuntsData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getEmailCopy = async (emc_id) => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `emailCopy/getById?emc_id=${emc_id}`;
    options.params = {
      emc_ser_id: props.serId,
    };

    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setEmailCopy(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const preview = () => {
    setIsLoading(true);
    const options = {
      method: "POST",
      url: `emailCopy/AdminPreview?user_id=${props.broadcast.bro_user_id}`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify({
      subject: emailCopy.emc_subject,
      body: emailCopy.emc_contentHTML,
    });

    client
      .request(options)
      .then((response) => {
        setSubjectPreview(response.subject);
        setPreviewTemplate(response.body);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const hanldePreviewPrevious = () => {
    setIsLoading(true);
    const options = {
      method: "POST",
      url: `emailCopy/AdminPreview?user_id=${props.broadcast.bro_user_id}`,
      headers: {
        "content-type": "application/json",
      },
    };
    options.data = JSON.stringify({
      subject: emailCopy.emc_subject,
      body: emailCopy.emc_contentHTML,
    });

    client
      .request(options)
      .then((response) => {
        setSubjectPrevious(response.subject);
        setPreviewPrevious(response.body);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const approve = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/approve`;
    options.data = JSON.stringify(props.broadcast);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setOpenSuccess(true);
        setIsLoading(false);
        setIsLoading(false);
        props.closeModal();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const reject = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/reject`;
    options.data = JSON.stringify(props.broadcast);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setOpenError(true);
        setIsLoading(false);
        setIsLoading(false);
        props.closeModal();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const changeSender = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/ChangeSender?bro_id=${props.broadcast.bro_id}`;
    options.data = JSON.stringify(senders.find((x) => x.ema_id == selectedSender));
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        props.broadcast.bro_ema_id = selectedSender;
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getDateValue = (date) => {
    return Moment(date).format("L");
  };

  const getStatus = (bro) => {
    switch (bro.bro_status) {
      case 0:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="secondary">
            Pending
          </MDTypography>
        );
      case 1:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="info">
            Sending
          </MDTypography>
        );
      case 2:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="success">
            Sent
          </MDTypography>
        );
      case 3:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="error">
            Failed
          </MDTypography>
        );
      case 4:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="warning">
            Paused
          </MDTypography>
        );
      case 5:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="error">
            Canceled
          </MDTypography>
        );
      case 11:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="info">
            Creating
          </MDTypography>
        );
      case 12:
        if (bro.bro_totalSent > 0)
          return (
            <MDTypography variant="subtitle2" fontWeight="medium" color="info">
              Sending
            </MDTypography>
          );
        else
          return (
            <MDTypography variant="subtitle2" fontWeight="medium" color="info">
              Building
            </MDTypography>
          );
      case 13:
        if (bro.bro_totalSent > 0)
          return (
            <MDTypography variant="subtitle2" fontWeight="medium" color="info">
              Sending
            </MDTypography>
          );
        else
          return (
            <MDTypography variant="subtitle2" fontWeight="medium" color="info">
              Ready
            </MDTypography>
          );
      case 14:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="warning">
            Approval pending
          </MDTypography>
        );
      case 15:
        return (
          <MDTypography variant="subtitle2" fontWeight="medium" color="error">
            Rejected
          </MDTypography>
        );
    }
  };

  const columns = [
    {
      label: "Status",
      name: "bro_status",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => getStatus(acconuntsData[dataIndex]),
      },
    },
    {
      label: "Name",
      name: "bro_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Recipients",
      name: "total_recipients",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Sent",
      name: "bro_totalSent",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Opens",
      name: "total_opens",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Clicks",
      name: "total_clicks",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Unsubscribes",
      name: "total_unsubscribes",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Complaints",
      name: "total_complaints",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Sales",
      name: "total_sales",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRender: (value) => "$ " + Math.round(value),
      },
    },
    {
      label: "Scheduled date",
      name: "bro_date",
      options: {
        filter: true,
        customBodyRender: (value) => getDateValue(value),
      },
    },
  ];

  const customToolbar = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "you don't have any broadcast",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "broadcast/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    downloadOptions: { filename: "broadcasts.csv", separator: "," },
    searchOpen: true,
    searchAlwaysOpen: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    searchPlaceholder: "Search",
    customToolbarSelect: customToolbar,
    options: {
      setTableProps: () => {
        return {
          // material ui v4 only
          size: "small",
        };
      },
    },
    onRowClick: (rowData, index) => {
      getEmailCopy(acconuntsData[index.dataIndex].bro_emc_id);
    },
  };

  return (
    <Dialog
      open={openModal}
      onClose={clearVariables}
      fullScreen
      sx={{
        //You can copy the code below in your theme
        background: "#F0F2F5",
        "& .MuiPaper-root": {
          background: "#F0F2F5",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Details for broadcast {props.broadcast != undefined ? props.broadcast.bro_name : ""}
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox component="form" role="form">
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              {previewTemplate != "" && (
                <MDBox mb={2}>
                  <Grid container mt={5}>
                    <Grid item mb={2} xs={12}>
                      <MDTypography variant="h6" fontWeight="medium" mt={1}>
                        Subject
                      </MDTypography>
                      <iframe
                        srcDoc={subjectPreview}
                        height="100"
                        width="100%"
                        allowFullScreen
                        style={{ border: "none" }}
                      />
                    </Grid>
                    <Grid item mb={2} xs={12}>
                      <MDTypography variant="h6" fontWeight="medium" mt={1}>
                        Body
                      </MDTypography>
                      <iframe
                        srcDoc={previewTemplate}
                        height="600"
                        width="100%"
                        allowFullScreen
                        style={{ border: "none" }}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              )}
              <Grid container mt={5}>
                <Grid item xs={4}>
                  <MDBox mb={2} display="flex" justifyContent="left">
                    <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                      Change sender
                    </MDTypography>
                    <Select
                      disabled={isLoading}
                      onChange={(e) => setSelectedSenders(e.target.value)}
                      value={selectedSender}
                      label="selec a sender to change"
                    >
                      {senders.map((item) => (
                        <MenuItem key={item.ema_id} value={item.ema_id}>
                          {item.ema_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>
                </Grid>
                <Grid item xs={4}>
                  <MDButton
                    onClick={approve}
                    disabled={isLoading}
                    autoFocus
                    variant="gradient"
                    color="success"
                  >
                    Approve
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                      />
                    )}
                  </MDButton>
                </Grid>
                <Grid item xs={4}>
                  <MDButton
                    onClick={reject}
                    disabled={isLoading}
                    autoFocus
                    variant="gradient"
                    color="error"
                  >
                    Reject
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: "relative", top: 4 }}
                      />
                    )}
                  </MDButton>
                </Grid>

                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    Previous broadcasts
                  </MDTypography>
                  <div style={{ height: "100%", width: "100%" }}>
                    <MUIDataTable data={acconuntsData} columns={columns} options={tableOptions} />
                    {previewPrevious != "" && (
                      <MDBox mb={2}>
                        <Grid container mt={5}>
                          <Grid item mb={2} xs={12}>
                            <MDTypography variant="h6" fontWeight="medium" mt={1}>
                              Subject
                            </MDTypography>
                            <iframe
                              srcDoc={subjectPrevious}
                              height="100"
                              width="100%"
                              allowFullScreen
                              style={{ border: "none" }}
                            />
                          </Grid>
                          <Grid item mb={2} xs={12}>
                            <MDTypography variant="h6" fontWeight="medium" mt={1}>
                              Body
                            </MDTypography>
                            <iframe
                              srcDoc={previewPrevious}
                              height="600"
                              width="100%"
                              allowFullScreen
                              style={{ border: "none" }}
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Card>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          autoFocus
          onClick={clearVariables}
          disabled={isLoading}
          variant="gradient"
          color="secondary"
        >
          Close
        </MDButton>
      </DialogActions>
      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content={`The broadcast ${
          props.broadcast != undefined ? props.broadcast.bro_name : ""
        } has been approved`}
        dateTime={""}
        open={openSuccess}
        onClose={closeSuccess}
        close={closeSuccess}
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="warning"
        content={`The broadcast ${
          props.broadcast != undefined ? props.broadcast.bro_name : ""
        } has been rejected`}
        title={"Rejected"}
        dateTime={""}
        open={openError}
        onClose={closeError}
        close={closeError}
        bgWhite
      />
    </Dialog>
  );
}
export default Details;
